<template>
  <div class="textile-report">
    <div>
      <ai-breadcrumb />
      <div class="new-button">
        <guide class="guide-button" />
      </div>
    </div>
    <div v-loading="queryLoading" class="search-content">
      <el-form
        class="search-form"
        :inline="true"
        size="small"
        @submit.native.prevent
      >
        <el-form-item
          class="search-form-gutter"
          :label="$t('analysis.platform')"
        >
          <el-select
            style="width: 88px"
            v-model="listQuery.platform"
            @change="changePlatform"
            filterable
          >
            <el-option
              v-for="item in platformList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('analysis.date')">
          <el-select style="width: 118px" v-model="currentDate" filterable>
            <el-option
              v-for="item in dateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('report.target')">
          <el-radio-group
            v-model="listQuery.targetType"
            @change="changeTarget"
            class="addition-vs"
          >
            <el-radio-button
              v-for="item in targetTypeList"
              :key="item.key"
              :label="item.key"
              >{{ item.value }}</el-radio-button
            >
          </el-radio-group>
          <el-select style="width: 149px" v-model="listQuery.target" filterable>
            <el-option
              v-for="item in targetList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
          <label class="date-vs">VS</label>
          <el-select
            style="width: 149px"
            v-model="listQuery.compareTarget"
            filterable
          >
            <el-option
              v-for="item in targetList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('report.product')">
          <el-select
            style="width: 105px"
            :placeholder="$t('common.all')"
            v-model="listQuery.product"
            @change="changeProduct"
            filterable
          >
            <el-option
              v-for="item in productList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('report.season')">
          <el-select
            style="width: 135px"
            :placeholder="$t('common.all')"
            v-model="listQuery.season"
            clearable
            filterable
          >
            <el-option
              v-for="item in seasonList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('report.category')">
          <el-select
            style="width: 115px"
            :placeholder="$t('common.all')"
            v-model="listQuery.category"
            @change="fetchItems"
            clearable
            filterable
          >
            <el-option
              v-for="item in categoryList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('report.item')">
          <el-select
            style="width: 165px"
            :placeholder="$t('common.all')"
            v-model="listQuery.item"
            clearable
            filterable
          >
            <el-option
              v-for="item in itemList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter">
          <el-button
            :disabled="
              !currentDate || !listQuery.target || !listQuery.compareTarget
            "
            size="small"
            :circle="true"
            type="primary"
            @click="fetchData"
            icon="el-icon-search"
          ></el-button>
        </el-form-item>
        <el-form-item class="search-form-gutter">
          <el-button
            :disabled="
              !currentDate || !listQuery.target || !listQuery.compareTarget
            "
            class="excel-dl-btn"
            size="small"
            :circle="true"
            @click="handleDownload"
            icon="el-icon-download"
          ></el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-loading="chartLoading" class="data-content">
      <div v-if="noData" style="padding-bottom: 20px; padding-top: 10px">
        No Data
      </div>
      <div style="text-align: left" :class="{ hidden: noData }">
        <el-form
          class="search-form"
          :inline="true"
          size="small"
          @submit.native.prevent
        >
          <el-form-item
            class="search-form-gutter"
            :label="$t('report.criterion')"
          >
            <el-radio-group
              v-model="currentCriterion"
              @change="changeCriterion"
            >
              <el-radio label="sales">{{ $t("report.sales") }}</el-radio>
              <el-radio label="style">{{ $t("report.style") }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <el-row>
          <el-col :span="12">
            <div id="left-chart-textile" />
          </el-col>
          <el-col :span="12">
            <div id="right-chart-textile" />
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { downloadFileAuth, serialize } from "@/utils/download-file";
import { aiApiRoot } from "@/utils/server";
import { getToken } from "@/utils/auth";
import {
  fetchUserBrandType,
  fetchUserBrands,
  fetchRecords,
} from "@/api/analysis";
import { fetchCategory, fetchItem, fetchTextileReportData } from "@/api/report";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import Guide from "@/components/Guide";
export default {
  components: {
    AiBreadcrumb,
    Guide,
  },
  data() {
    return {
      currentDate: "",
      currentCriterion: "sales",
      platformList: [
        { key: "tmall", value: "TMall" },
        { key: "tiktok", value: "Tiktok" },
      ],
      targetTypeList: [
        { key: "group", value: this.$t("report.group") },
        { key: "brand", value: this.$t("common.brand") },
      ],
      targetList: [],
      dateList: [],
      seasonList: [
        { key: "当年", value: this.$t("report.currentYear") },
        { key: "往年", value: this.$t("report.previousYear") },
      ],
      productList: [
        { key: "fashion", value: this.$t("report.fashion") },
        { key: "etc", value: this.$t("report.etc") },
      ],
      categoryList: [],
      itemList: [],
      listQuery: {
        platform: "tmall",
        targetType: "group",
        target: "",
        compareTarget: "",
        date: "",
        season: "",
        product: "fashion",
        category: "",
        item: "",
      },
      graphData: {
        first: {},
        second: {},
      },
      leftTextileChart: null,
      rightTextileChart: null,
      chartLoading: false,
      queryLoading: false,
      hasFirstLoad: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.leftTextileChart = echarts.init(
      document.getElementById("left-chart-textile")
    );
    this.rightTextileChart = echarts.init(
      document.getElementById("right-chart-textile")
    );

    this.fetchDates();
    this.fetchCategoryList();
  },
  beforeUnmout() {
    window.removeEventListener("resize", this.handleResize);
    this.leftTextileChart.dispose();
    this.rightTextileChart.dispose();
  },
  methods: {
    initGraphData() {
      this.graphData = {
        first: {},
        second: {},
      };
    },
    handleResize() {
      if (this.leftTextileChart) {
        this.leftTextileChart.resize();
      }
      if (this.rightTextileChart) {
        this.rightTextileChart.resize();
      }
    },
    fetchDates() {
      this.queryLoading = true;
      this.dateList = [];
      this.currentDate = "";
      fetchRecords({
        type: "sales",
        period: "week",
        platform: this.listQuery.platform,
        limit: -1,
      })
        .then((response) => {
          if (response.success) {
            response.result &&
              response.result.items &&
              response.result.items.forEach((e) => {
                this.dateList.push({
                  value: e.date,
                  label: e.linkDate,
                });
              });
            if (this.dateList && this.dateList.length > 0) {
              this.currentDate = this.dateList[0].value;
            }
          }
          this.queryLoading = false;
        })
        .catch(() => {
          this.queryLoading = false;
        });
    },
    fetchTargets() {
      this.queryLoading = true;
      this.targetList = [];
      this.listQuery.target = "";
      this.listQuery.compareTarget = "";
      if (this.listQuery.targetType === "group") {
        fetchUserBrandType()
          .then((response) => {
            if (response.success) {
              this.targetList = response.result.items
                ? response.result.items.map((t) => {
                    return { key: t, value: t };
                  })
                : [];
              if (this.targetList && this.targetList.length > 0) {
                this.listQuery.target = this.targetList[0].key;
                if (this.targetList.length > 1) {
                  this.listQuery.compareTarget = this.targetList[1].key;
                } else {
                  this.listQuery.compareTarget = this.targetList[0].key;
                }
                this.fetchData(true);
              }
            }
            this.queryLoading = false;
          })
          .catch(() => {
            this.queryLoading = false;
          });
      } else {
        fetchUserBrands({
          platform: this.listQuery.platform,
          dates: this.currentDate,
        })
          .then((response) => {
            if (response.success) {
              this.targetList = response.result.items
                ? response.result.items.map((t) => {
                    return { key: t.id + "", value: t.name };
                  })
                : [];
              if (this.targetList && this.targetList.length > 0) {
                this.listQuery.target = this.targetList[0].key;
                if (this.targetList.length > 1) {
                  this.listQuery.compareTarget = this.targetList[1].key;
                } else {
                  this.listQuery.compareTarget = this.targetList[0].key;
                }
              }
            }
            this.queryLoading = false;
          })
          .catch(() => {
            this.queryLoading = false;
          });
      }
    },
    fetchCategoryList() {
      this.queryLoading = true;
      this.listQuery.category = "";
      fetchCategory(this.listQuery.product)
        .then((response) => {
          if (response.success) {
            this.categoryList = response.result ? response.result : [];
            this.fetchItems();
          }
          this.queryLoading = false;
        })
        .catch(() => {
          this.queryLoading = false;
        });
    },
    fetchItems() {
      this.queryLoading = true;
      this.listQuery.item = "";
      this.itemList = [];
      fetchItem(this.listQuery.product, { category: this.listQuery.category })
        .then((response) => {
          if (response.success) {
            this.itemList = response.result ? response.result : [];
          }
          this.queryLoading = false;
        })
        .catch(() => {
          this.queryLoading = false;
        });
    },
    fetchData(firstLoad) {
      if (
        !this.currentDate ||
        !this.listQuery.target ||
        !this.listQuery.compareTarget
      ) {
        return;
      }
      this.listQuery.date = this.currentDate;
      if (firstLoad === true) {
        if (this.hasFirstLoad) {
          return;
        }
        this.hasFirstLoad = true;
      }
      this.chartLoading = true;
      this.initGraphData();
      fetchTextileReportData(this.listQuery)
        .then((response) => {
          if (response.success) {
            this.graphData = response.result;
            if (
              this.graphData &&
              this.graphData.first["textiles"] &&
              this.graphData.second["textiles"]
            ) {
              this.$nextTick(() => {
                this.drawTextileChart();
              });
            }
          }
          this.chartLoading = false;
        })
        .catch(() => {
          this.chartLoading = false;
        });
    },
    drawTextileChart() {
      let generateChart = (chart, data) => {
        let pieData = [];
        if (this.currentCriterion === "sales") {
          pieData = data["salesPercentage"] ? data["salesPercentage"] : [];
        } else {
          pieData = data["stylePercentage"] ? data["stylePercentage"] : [];
        }
        let serieData = pieData.map((e, i) => {
          return {
            value: e,
            name: data["textiles"][i],
          };
        });
        serieData = serieData.sort(function (a, b) {
          return b.value - a.value;
        });
        let legendData = serieData.map((e) => {
          return e.name;
        });
        let option = {
          title: {
            text: this.$t("report.textile"),
            padding: [5, 10],
            textStyle: {
              fontSize: 16,
            },
          },
          tooltip: {
            trigger: "item",
            valueFormatter: (value) => value + "%",
          },
          legend: {
            type: "scroll",
            // orient: "vertical",
            // top: "center",
            // width: 20,
            // right: 5,
            bottom: "5%",
            data: legendData,
            reversed: true,
            selector: [
              {
                type: "all",
                title: this.$t("common.selectAll"),
              },
              {
                type: "inverse",
                title: this.$t("common.selectReverse"),
              },
            ],
          },
          series: [
            {
              bottom: "40",
              name:
                this.currentCriterion === "sales"
                  ? this.$t("report.sales")
                  : this.$t("report.style"),
              type: "pie",
              data: serieData,
              minAngle: 5,
              avoidLabelOverlap: true,
              // center: ["50%", "50%"],
              radius: [0, "57%"],
            },
          ],
        };
        chart.setOption(option, true);
      };
      generateChart(this.leftTextileChart, this.graphData.first);
      generateChart(this.rightTextileChart, this.graphData.second);
    },
    handleDownload() {
      const loading = this.$loading({
        lock: true,
        text: this.$t("common.preparingData"),
      });
      let query = this.listQuery;
      query.date = this.currentDate;
      downloadFileAuth(
        aiApiRoot + "/v1/report/textile/export?" + serialize(query),
        query.date + "_textile_report_" + Date.now() + ".xlsx",
        getToken(),
        () => {
          loading.close();
        }
      );
    },
    changePlatform() {
      this.fetchDates();
    },
    changeProduct() {
      this.fetchCategoryList();
    },
    changeTarget() {
      this.fetchTargets();
    },
    changeCriterion() {
      this.drawTextileChart();
    },
  },
  computed: {
    noData() {
      return (
        !this.graphData ||
        !this.graphData.first["textiles"] ||
        !this.graphData.second["textiles"]
      );
    },
  },
  watch: {
    currentDate() {
      this.fetchTargets();
    },
  },
};
</script>

<style lang="scss" scoped>
.textile-report {
  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;
  }
  .search-content {
    background-color: #fff;
    padding: 10px 0 0 10px;
    margin-bottom: 10px;

    .el-form-item {
      margin-bottom: 10px;
    }

    .search-form {
      width: auto;

      .search-form-gutter {
        margin-right: 10px;
      }
    }

    .addition-vs {
      vertical-align: inherit;
      margin-right: 12px;
    }

    .date-vs {
      vertical-align: middle;
      font-size: 14px;
      color: #606266;
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  .data-content {
    text-align: center;
    background-color: #fff;
    min-height: 30px;
    font-size: 14px;
    color: #606266;
    padding: 10px 0 0 10px;

    .hidden {
      visibility: hidden;
      height: 0px;
      div {
        overflow: hidden;
      }
    }
  }

  #left-chart-textile {
    width: 100%;
    min-height: 248px;
    height: calc(100vh - 100px - 52.5px - 96px - 10.5px - 50px - 10px - 10px);
  }

  #right-chart-textile {
    width: 100%;
    min-height: 248px;
    height: calc(100vh - 100px - 52.5px - 96px - 10.5px - 50px - 10px - 10px);
  }
}
</style>